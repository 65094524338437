import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Flex, Image, Text, Link } from 'rebass/styled-components'

import Container from './container'
import FooterLogo from '../images/footer-logo.svg'
// import IconMobilePhone from '../images/icons/mobile.svg'
// import IconEmail from '../images/icons/email.svg'

// const Icon = (props) => <Image sx={{ mr: 2 }} {...props} />

const ContactItem = ({ children, href, ...rest }) => {
  return (
    <Link
      variant="primarySmall"
      href={href}
      sx={{ lineHeight: 1.5, fontSize: 3, color: 'white', textDecoration: 'none' }}
      {...rest}
    >
      {children}
    </Link>
  )
}

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          address {
            street
            city
            postal
            country
          }
        }
      }
    }
  `)
  const { title, address } = site.siteMetadata
  const { street, city, postal, country } = address
  const currentYear = new Date().getFullYear()

  return (
    <Box variant="footer" as="footer">
      <Container width="800px">
        <Flex
          sx={{
            mb: 4,
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: ['100%', '48%'], mb: [4, 0] }}>
            <Box variant="footer.address" as="ul">
              <li>
                <strong>{title}</strong>
              </li>
              <li>{street}</li>
              <li>{`${postal} ${city}`}</li>
              <li>{country}</li>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'grid',
              alignContent: 'start',
              gap: 2,
              width: ['100%', '48%'],
            }}
          >
            <ContactItem href="tel:+420777580564">+420 777 580 564</ContactItem>

            <ContactItem href="mailto:info@czech-heat.cz">
              info@czech-heat.cz
            </ContactItem>
          </Box>
        </Flex>

        <Flex sx={{ alignItems: 'center' }}>
          <Image src={FooterLogo} alt="" sx={{ height: '3rem', mr: 4 }} />

          <Text variant="copyright" as="p">
            {`Copyright © 2019-${currentYear} ${title}`}
          </Text>
        </Flex>
      </Container>
    </Box>
  )
}

ContactItem.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
}

export default Footer
