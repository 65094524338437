const colors = {
  text: '#322626',
  background: '#fff',
  primary: '#F04E22',
  secondary: '#2A3890',
  green: '#1AB220',
  muted: '#f6f6f9',
  gray: '#dddddf',
  highlight: 'hsla(205, 100%, 40%, 0.125)',
}

const light = {
  colors,
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body:
      '"Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    heading:
      '"Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [0, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48],
  fontWeights: {
    body: 'normal',
    heading: 'bold',
    bold: 'bold',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  sizes: {
    avatar: 48,
  },
  radii: {
    default: 4,
    pill: '3rem',
    circle: 99999,
  },
  shadows: {
    card: '0 6px 32px rgba(0, 0, 0, .05)',
    header: '0 4px 32px rgba(0, 0, 0, .125)',
  },
  // rebass variants
  text: {
    body: {
      pb: 4,
      color: 'text',
    },
    heading: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    hero: {
      title: {
        mb: 4,
        color: 'white',
        fontSize: 9,
      },
      subtitle: {
        mb: 5,
        color: 'white',
        fontSize: 5,
      },
    },
    tile: {
      title: {
        color: 'white',
        fontSize: 6,
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
    copyright: {
      fontSize: 1,
    },
  },
  variants: {
    section: {
      py: 5,
    },
    card: {
      p: 3,
      boxShadow: 'card',
    },
    header: {
      position: ['static', 'static', 'absolute'],
      width: '100%',
      maxWidth: '75rem',
      top: [0, 0, 4],
      left: ['auto', 'auto', '50%'],
      bg: 'white',
      borderBottomColor: 'primary',
      borderBottomStyle: 'solid',
      borderBottomWidth: 8,
      boxShadow: 'header',
      transform: ['', '', 'translateX(-50%)'],
      zIndex: 100,
    },
    headerBody: {
      py: [3, 3, 0],
      width: '100%',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexDirection: ['row', 'row', 'row'],
      justifyContent: ['space-between', 'space-between'],
    },
    logo: {
      mb: [0, 0, 0],
      lineHeight: 0,
      maxWidth: ['60%', '30%'],
      height: '4rem',
      display: 'block',
    },
    menuToggle: {
      display: ['block', 'none', 'none'],
      border: 'none',
      outline: 'none',
      px: 3,
      py: 2,
      bg: 'primary',
      color: 'white',
      fontFamily: 'body',
      fontSize: 3,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    nav: {
      list: {
        m: 0,
        pt: [3, 3, 0],
        height: '100%',
        listStyle: 'none',
        flexDirection: ['column', 'row', 'row'],
        alignItems: 'center',
        justifyContent: 'center',
        display: ['none', 'flex', 'flex'],
        width: ['100%', 'auto', 'auto'],
      },
      listOpen: {
        variant: 'variants.nav.list',
        display: ['flex', 'flex', 'flex'],
      },
      item: {
        display: 'block',
      },
      link: {
        px: [2, 2, 3],
        py: [2, 2, '2rem'],
        display: 'block',
        width: '100%',
        color: 'text',
        fontSize: [2, 1, 3],
        fontWeight: 'bold',
        letterSpacing: '-1px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        transition: 'background 200ms ease-out',
        '&.active, &:hover, &:focus': {
          bg: 'primary',
          color: 'white',
        },
      },
    },
    footer: {
      py: 5,
      bg: 'secondary',
      color: 'white',
      address: {
        fontSize: 3,
        lineHeight: 1.75,
      },
    },
    hero: {
      bg: 'secondary',
      body: {
        pt: ['10rem', '10rem', '16rem'],
        height: 'auto',
      },
      text: {
        maxWidth: ['100%', '100%', '60%'],
      },
    },
    heroFullHeight: {
      variant: 'variants.hero',
      minHeight: '100vh',
    },
    link: {
      hero: {
        px: 5,
        py: 3,
        bg: 'primary',
        color: 'white',
        fontFamily: 'body',
        fontSize: 5,
        fontWeight: 'bold',
        outline: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        transition: 'all 200ms',
        '&:hover': { bg: 'secondary', color: 'primary' },
      },
    },
    primarySmall: {
      variant: 'buttons.primarySmall',
      textDecoration: 'none'
    },
    tiles: {
      maxWidth: '120rem',
      flexDirection: ['column', 'row'],
      alignItems: 'stretch',
      justifyContent: 'stretch',
    },
    tile: {
      flexBasis: '50%',
      flexGrow: 1,
      textDecoration: 'none',
      iconWrapper: {
        mb: 3,
        p: 4,
        bg: 'primary',
        borderRadius: 'circle',
      },
      icon: {
        width: '4rem',
        height: '4rem',
      },
      body: {
        py: 5,
        px: 2,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      },
    },
    'tile-primary': {
      variant: 'variants.tile',
    },
    'tile-green': {
      variant: 'variants.tile',
    },
  },
  buttons: {
    primary: {
      px: 5,
      py: 3,
      bg: 'primary',
      borderRadius: '0',
      color: 'white',
      fontFamily: 'body',
      fontSize: 5,
      fontWeight: 'bold',
      outline: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      textAlign: 'center',
      transition: 'all 200ms',
      '&:hover, &:focus': {
        bg: 'secondary',
      },
    },
    hero: {
      variant: 'buttons.primary',
    },
    primarySmall: {
      variant: 'buttons.primary',
      px: [3, 4],
      py: 2,
      color: 'white',
      fontSize: [3, 4],
      textAlign: 'center',
      justifyContent: 'center',
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 2px',
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'white',
      bg: 'secondary',
    },
  },
  styles: {
    root: {
      pt: [0, 0, 4],
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
}

export default light
