import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { useLocation } from '@reach/router'
import { Box, Flex } from 'rebass/styled-components'

const NavList = ({ children, isOpen, ...rest }) => (
  <Box variant={isOpen ? 'nav.listOpen' : 'nav.list'} as="ul" {...rest}>
    {children}
  </Box>
)

const NavItem = ({ children, ...rest }) => (
  <Box variant="nav.item" as="li" {...rest}>
    {children}
  </Box>
)

const NavLink = ({ children, ...rest }) => (
  <Box variant="nav.link" as={GatsbyLink} {...rest}>
    {children}
  </Box>
)

const menu = [
  {
    link: '/',
    label: 'Hlavní strana',
  },
  {
    link: '/produkty',
    label: 'Produkty',
  },
  {
    link: '/stavebni-cinnost',
    label: 'Stavební činnost',
  },
  {
    link: '/reference',
    label: 'Reference',
  },
  {
    link: '/kontakty',
    label: 'Kontakty',
  },
]

const Nav = ({ isOpen }) => {
  const location = useLocation()

  return (
    <NavList isOpen={isOpen}>
      {menu &&
        menu.map((item) => (
          <NavItem key={item.link}>
            <NavLink
              to={item.link}
              className={item.link === location.pathname ? 'active' : ''}
            >
              {item.label}
            </NavLink>
          </NavItem>
        ))}
    </NavList>
  )
}

NavList.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
}

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default Nav
