import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'
import BackgroundImage from 'gatsby-background-image'
import { Link as SmoothLink } from 'react-scroll'

import Container from './container'
import LinkButton from './link-button'

const Hero = ({
  title,
  subtitle,
  image,
  ctaLabel,
  ctaLink,
  fullHeight,
  smoothLabel,
  smoothLink,
}) => {
  return (
    <Flex variant={fullHeight ? 'heroFullHeight' : 'hero'}>
      <Box
        as={BackgroundImage}
        fluid={image.childImageSharp.fluid}
        sx={{
          width: '100%',
          '&:after, &:before': {
            mixBlendMode: 'lighten !important',
            opacity: '0.3 !important',
          },
        }}
        alt=""
      >
        <Box variant="hero.body">
          <Container>
            <Box variant="hero.text">
              {title && (
                <Heading variant="hero.title" as="h1">
                  {title}
                </Heading>
              )}

              {subtitle && (
                <Text variant="hero.subtitle" as="p">
                  {subtitle}
                </Text>
              )}

              {smoothLabel && smoothLink && (
                <LinkButton
                  variant="link.hero"
                  as={SmoothLink}
                  to="about"
                  smooth
                  offset={-100}
                  duration={200}
                >
                  {smoothLabel}
                </LinkButton>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </Flex>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  fullHeight: PropTypes.bool,
  smoothLabel: PropTypes.string,
  smoothLink: PropTypes.string,
}

Hero.defaultProps = {
  fullHeight: false,
}

export default Hero
