import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'
import { Box, Flex } from 'rebass/styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Container from './container'
import Logo from '../images/logo-czech_heat.svg'
import Nav from './nav'

const StyledLogo = styled.img`
  height: 100%;
  max-width: 100%;
  max-height: 64px;
  line-height: 0;
`

const Header = ({ siteTitle }) => {
  const [fixed, setFixed] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      const isFixed = currPos.y < -32
      setFixed(isFixed)
    },
    [fixed]
  )

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <Box
      variant="header"
      sx={{
        position: fixed
          ? ['static', 'static', 'fixed']
          : ['static', 'static', 'absolute'],
        top: fixed ? [0, 0, 0] : '',
        left: fixed ? [0, 0, 0] : ['auto', 'auto', '50%'],
        right: fixed ? [0, 0, 0] : ['auto', 'auto', 'auto'],
        transform: fixed
          ? ['', '', 'translateX(0)']
          : ['', '', 'translateX(-50%)'],
        maxWidth: fixed ? 'none' : '75rem',
      }}
      as="header"
    >
      <Container>
        <Flex variant="headerBody">
          <Box variant="logo" as={GatsbyLink} to="/">
            <StyledLogo src={Logo} alt={siteTitle} />
          </Box>

          <Box variant="menuToggle" as="button" type="button" onClick={toggleMenu}>
            Menu
          </Box>

          <Nav isOpen={menuOpen} />
        </Flex>
      </Container>
    </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
